import React, {useState} from "react"

import MediaQuery from "react-responsive/src/Component"

import Holz from "../../images/benefiz/holz.jpg";
import LogoTransparent from "../../images/benefiz/Logo_Transparent.svg";
// import Leaf from "../../images/benefiz/leaf.png"
import Leaf from "../../images/benefiz/leaf_crop.png"

import {graphql, Link} from "gatsby";
import "../../styles/index.scss"
import BackgroundImage from "gatsby-background-image";


const Benefiz = ({ _path, data, className }) => {

    return(
        <BackgroundImage fluid={data.indexImage.childImageSharp.fluid} style={{display:"flex",flexDirection:"column",
            justifyContent:"center", alignItems:"center",
            backgroundImage: `url(${Holz})`, backgroundRepeat: 'repeat-y',
            backgroundSize: 'cover',
            width: "100%",
            height: "100vh"
        }}>
            <MediaQuery maxWidth="450px">
                {(matches) =>
                    matches
                        ?
                        <div>
                            <img src={LogoTransparent} width="25%" alt={'logo'} style={{marginTop:"-8%", marginLeft:"100px"}}/>
                            <img src={Leaf} width="50%" alt={'leaf'} style={{marginLeft:"-35x"}}/>
                        </div>
                        :
                        <div style={{display:"flex"}} >
                            <img src={LogoTransparent} width="45%" alt={'logo'} style={{marginTop:"-8%"}}/>
                            <img src={Leaf} width="70%" alt={'leaf'} style={{marginLeft:"-19%"}}/>
                        </div>
                }
            </MediaQuery>
            <MediaQuery maxWidth="450px">
                {(matches) =>
                    matches
                        ? <div className={"test"}
                               style={{display:"flex", flexDirection:"column",
                            justifyContent:"center", alignItems:"center",textAlign:"center", width:"90%", height:"100%",color:"black",
                        }}>
                            <p style={{fontWeight:"bold", fontSize:"14px"}}>
                                Hey, schön dass du unsere Seite besucht.
                            </p>
                            <p style={{fontSize:"12px"}}>
                                Du fragst dich bestimmt, wer dir eigentlich grade die Möglichkeit gibt, dass<br/>
                                du dich vollends mit einem kühlen Getränk in dem Liegestuhl entspannen
                                kannst? Und warum ist da überhaupt so eine rote Eiche drauf?</p>
                            <p style={{fontSize:"12px"}}>Die rote Eiche gehört zu den Oakies von Red Oak Consulting.<br/>
                                Wir entwickeln Software für unsere Kunden und tun nebenbei noch das ein
                                oder andere Gute für die Gesellschaft.</p>

                            <Link to="/benefiz-open" style={{
                                backgroundColor: "rgba(199, 0, 0, 0.8)",
                                color: "white",
                                border: "none",
                                padding: "10px",
                                fontWeight: "bold",
                                marginTop: "10px",
                                marginBottom: "10px",
                                cursor:"pointer"
                            }}>
                                Erfahre mehr hier über uns
                            </Link>

                            <p style={{color:"black", fontWeight:"bold", fontSize:"14px", paddingTop:"10px", paddingBottom:"10px"}}>
                                Wir wünschen dir weiterhin viel Spaß beim Generationenlauf 2023 im Haus Eichenpark!
                            </p>
                        </div>
                        : <div style={{display:"flex", flexDirection:"column",
                            justifyContent:"center", alignItems:"center",
                            textAlign:"center", width:"100%", height:"100%",color:"black", lineHeight:"30px"}}>
                            <p style={{fontWeight:"bold", fontSize:"20px"}}>
                                Hey, schön dass du unsere Seite besucht.
                            </p>
                            <p style={{fontSize:"15px"}}>Du fragst dich bestimmt, wer dir eigentlich grade die Möglichkeit gibt, dass<br/>
                                du dich vollends mit einem kühlen Getränk in dem Liegestuhl entspannen
                                kannst? Und warum ist da überhaupt so eine rote Eiche drauf?</p>
                            <p style={{fontSize:"15px"}}>Die rote Eiche gehört zu den Oakies von Red Oak Consulting.<br/>
                                Wir entwickeln Software für unsere Kunden und tun nebenbei noch das ein
                                oder andere Gute für die Gesellschaft.</p>

                            <Link to="/benefiz-open" style={{
                                backgroundColor: "rgba(199, 0, 0, 0.8)",
                                color: "white",
                                border: "none",
                                padding: "10px",
                                fontWeight: "bold",
                                marginTop: "30px",
                                // marginBottom: "10px",
                                // cursor:"pointer",
                                textDecoration:"none"
                            }}>
                                Erfahre mehr hier über uns
                            </Link>

                            <p style={{color:"black", fontWeight:"bold", fontSize:"20px", lineHeight:"40px", marginTop:"30px"}}>
                                Wir wünschen dir weiterhin viel Spaß beim Generationenlauf 2023 im Haus Eichenpark!
                            </p>
                        </div>
                }
            </MediaQuery>
        </BackgroundImage>

    )
}

export default Benefiz;

export const pageQuery = graphql`
  query {
    indexImage: file(relativePath: { eq: "benefizOpen/holz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
